import React from 'react'
import About from './AboutSection'
import Banner from './BannerSection'
import Brand from './BrandSection'
import Category from './CategorySection'
import Cta from './CtaSection'
import Event from './EventSection'
import Hero from './HeroSection'
import Service from './ServiceSection'

function HomeMain() {
    return (
        <main>
            {/* hero-start */}
            <Hero />
            <Brand/>
            <Service/>
            <About/>
            {/* hero-end */}

           
            {/* category-start */}
            <Category />
            {/* category-end */}

            {/* banner-start */}
            <Banner />
            {/* banner-end */}

            {/* course-start */}
            {/* <CourseTab /> */}
            {/* course-end */}

            {/* event-start */}
            <Event />
            {/* event-end */}

            {/* tab-start */}
            {/* <PriceTab /> */}
            {/* tab-end */}

            {/* cta-start */}
            <Cta />
            {/* cta-end */}

        </main>
    )
}

export default HomeMain