export let ResourceActions = {
    EmployeeManagement :{
        ViewAll : "employee-management:view-all",
        Add: "employee-management:add",
        Edit: "employee-management:edit",
        Delete: "employee-management:delete"
    },
    CourseTab: {
        ViewAll : "course-tab:view-all",
        ViewSelected : "course-tab:view-selected",
        RemoveSelected: "course-tab:remove-selected"
    }
}