import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import antIcon from "../../uielements/Spinner/spin";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { RootState } from "../../../config/store";
import { connect, useDispatch, useSelector } from "react-redux";
import { CourseModel } from "../../../store/course/reducer";
import { Modal, Row, Skeleton, Spin } from "antd";
import MyCoursesTab from "./MyCoursesTab";
import {
  actions,
  selectCourse,
  getSelectedCourses,
} from "../../../store/course/actions";
import {
  selectDoneAction,
  selectError,
  selectLoader,
} from "../../../store/ui/selector";
import Restricted from "../../Restricted/Restricted";
import { ResourceActions } from "../../../config/res_action/resourceActions";
import CardSkeleton from "../../uielements/CardSkeleton/CardSkeleton";
import { removeDoneActions } from "../../../store/ui/actions";
import * as _ from "lodash";

const CourseMain = (props: {
  isLoading: boolean;
  isSelectLoading: boolean;
  isDoneSelectCourse: boolean;
}) => {
  const { allCourses, slectedCourses } = useSelector(
    (store: RootState) => store.course
  );

  const [localCourses, setLocalCourses] = useState([] as CourseModel[]);
  const [feCourses, setFeCourses] = useState(slectedCourses);
  const [selectedCourse, setSelectedCourse] = useState({} as CourseModel);

  const dispatch = useDispatch();

  function titleCase(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }

  function enroll(selected_course: CourseModel) {
    dispatch(selectCourse({ CourseId: selected_course.CourseId as string }));
    setSelectedCourse(selected_course);
  }

  useEffect(() => {
    if (props.isDoneSelectCourse) {
      setFeCourses([...feCourses, selectedCourse]);
      dispatch(getSelectedCourses());
      dispatch(removeDoneActions([actions.SELECT_COURSE]));
    }
  }, [props.isDoneSelectCourse]);

  useEffect(() => {
    setLocalCourses(_.differenceWith(localCourses, feCourses, _.isEqual));
    // console.log(feCourses);
  }, [feCourses]);

  useEffect(() => {
    setLocalCourses(_.differenceWith(allCourses, slectedCourses, _.isEqual));
  }, [props.isLoading]);

  return (
    <section className="course__area pt-115 pb-120 grey-bg">
      <Restricted to={ResourceActions.CourseTab.ViewAll}>
        <Tabs id="react-tabs-276">
          <div className="container">
            <div className="row align-items-end">
              <div className="section__title-wrapper mb-40">
                <h2
                  style={{ color: "blue", textAlign: "center" }}
                  className="section__title"
                >
                  Hey! Welcome to the{" "}
                  <span className="yellow-bg yellow-bg-big">
                    {" "}
                    <img
                      src="/assets/img/shape/yellow-bg.png"
                      alt="img not found"
                    />
                    Dashboard
                  </span>
                </h2>
                <p style={{ textAlign: "center" }}>
                  Manage your employees & assign them cource.
                </p>
              </div>
              <div className="col-xxl-5 col-xl-6 col-lg-6">
                <div className="section__title-wrapper mb-60">
                  <h2 className="section__title">
                    Find the Right
                    <br />
                    Online{" "}
                    <span className="yellow-bg yellow-bg-big">
                      Course
                      <img
                        src="/assets/img/shape/yellow-bg.png"
                        alt="img not found"
                      />
                    </span>{" "}
                    for you
                  </h2>
                  <p>
                    You don't have to struggle alone, you've got our assistance
                    and help.
                  </p>
                </div>
              </div>
              <div className="col-xxl-7 col-xl-6 col-lg-6">
                <div className="course__menu d-flex justify-content-lg-end mb-60">
                  <div className="masonary-menu filter-button-group">
                    <TabList>
                      <Tab>
                        <button>
                          See All <span className="tag">new</span>
                        </button>
                      </Tab>
                      <Tab>
                        <button>Selected</button>
                      </Tab>
                    </TabList>
                  </div>
                </div>
              </div>
            </div>

            <TabPanel>
              <div className="row">
                {props.isSelectLoading && (
                  <Modal
                    centered
                    visible={props.isSelectLoading}
                    title="Adding Course"
                    okButtonProps={{ disabled: true }}
                    cancelButtonProps={{ disabled: true }}
                    footer={[<></>]}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spin size="large" tip="Loading..." />
                    </div>
                  </Modal>
                )}
                {props.isLoading ? (
                  <>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <CardSkeleton />
                    </div>
                  </>
                ) : (
                  localCourses.map((course: CourseModel, index: number) => {
                    return (
                      <div
                        key={index}
                        className="col-xxl-4 col-xl-4 col-lg-4 col-md-6"
                      >
                        <div className="course__item white-bg mb-30 fix">
                          <div className="course__thumb w-img p-relative fix">
                            <Link
                              to="/course-details"
                              state={{ selectedCourse: course }}
                            >
                              <img
                                src={course.Thumbnail as string}
                                loading="lazy"
                                alt="img not found"
                              />
                            </Link>
                            <div className="course__tag">
                              <Link to="/course-details">
                                <a className="purple">
                                  {course.Category?.map((cat: string) =>
                                    titleCase(cat)
                                  ).join(", ")}
                                </a>
                              </Link>
                            </div>
                          </div>
                          <div className="course__content">
                            <div className="course__meta d-flex align-items-center justify-content-between">
                              <div className="course__lesson">
                                <span>
                                  <i>
                                    <FontAwesomeIcon icon={["fas", "book"]} />
                                  </i>
                                  {course.Duration} Lessons
                                </span>
                              </div>
                              <div className="course__rating">
                                <span>
                                  <i>
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                  </i>
                                  {course.Rating}
                                </span>
                              </div>
                            </div>
                            <h3 className="course__title">
                              <Link
                                to="/course-details"
                                state={{ selectedCourse: course }}
                              >
                                {course.CourseName?.substring(0, 50)}...
                              </Link>
                            </h3>
                            <div className="course__teacher d-flex align-items-center">
                              <div className="course__teacher-thumb mr-15">
                                <img
                                  src={course.InstituteLogo as string}
                                  alt="img not found"
                                />
                              </div>
                              <h6>
                                <Link to="/instructor-details">
                                  <a>{titleCase(course.OfferedBy as string)}</a>
                                </Link>
                              </h6>
                            </div>
                          </div>
                          <div className="course__more d-flex justify-content-between align-items-center">
                            {/* <div className="course__status">
                              <span>Free</span>
                           </div> */}
                            <div className="course__btn w-100">
                              <button
                                className="e-btn  w-100"
                                onClick={() => enroll(course as CourseModel)}
                              >
                                {/* {props.isSelectLoading && antIcon} */}
                                Select this course for employees
                                {/* <i><FontAwesomeIcon icon={['fas', 'arrow-right']} /></i> */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <MyCoursesTab />
            </TabPanel>
          </div>
        </Tabs>
      </Restricted>
      <Restricted to={ResourceActions.CourseTab.ViewSelected}>
        <Tabs id="react-tabs-276">
          <div className="container">
            <div className="row align-items-end">
              <div className="section__title-wrapper mb-40">
                <h2
                  style={{ color: "blue", textAlign: "center" }}
                  className="section__title"
                >
                  Hey! Welcome to the{" "}
                  <span className="yellow-bg yellow-bg-big">
                    {" "}
                    <img
                      src="/assets/img/shape/yellow-bg.png"
                      alt="img not found"
                    />
                    Dashboard
                  </span>
                </h2>
                <p style={{ textAlign: "center" }}>
                  Manage your employees & assign them cource.
                </p>
              </div>
              <div className="col-xxl-5 col-xl-6 col-lg-6">
                <div className="section__title-wrapper mb-60">
                  <h2 className="section__title">
                    Find the Right
                    <br />
                    Online{" "}
                    <span className="yellow-bg yellow-bg-big">
                      Course
                      <img
                        src="/assets/img/shape/yellow-bg.png"
                        alt="img not found"
                      />
                    </span>{" "}
                    for you
                  </h2>
                  <p>
                    You don't have to struggle alone, you've got our assistance
                    and help.
                  </p>
                </div>
              </div>
              <div className="col-xxl-7 col-xl-6 col-lg-6">
                <div className="course__menu d-flex justify-content-lg-end mb-60">
                  <div className="masonary-menu filter-button-group">
                    <TabList>
                      <Tab>
                        <button>
                          Courses for me<span className="tag">new</span>
                        </button>
                      </Tab>
                    </TabList>
                  </div>
                </div>
              </div>
            </div>

            <TabPanel>
              <MyCoursesTab />
            </TabPanel>
          </div>
        </Tabs>
      </Restricted>
    </section>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isSelectLoading: selectLoader(actions.SELECT_COURSE)(state),
    isDoneSelectCourse: selectDoneAction(actions.SELECT_COURSE)(state),
    error: selectError(actions.SELECT_COURSE)(state),
  };
};

export default connect(mapStateToProps)(CourseMain);
