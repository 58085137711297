import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <section className="about__area pt-90 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
              <div className="about__thumb-wrapper">
                <div className="about__review">
                  <h5>
                    {" "}
                    <span>8,200+</span> five ster reviews
                  </h5>
                </div>
                <div className="about__thumb ml-100">
                  <img src="assets/img/about/about.jpg" alt="img not found" />
                </div>
                <div className="about__banner mt--210">
                  <img
                    src="assets/img/about/about-banner.jpg"
                    alt="img not found"
                  />
                </div>
                <div className="about__student ml-270 mt--80">
                  <Link to="/course-details">
                    <img
                      src="assets/img/about/student-4.jpg"
                      alt="img not found"
                    />
                    <img
                      src="assets/img/about/student-3.jpg"
                      alt="img not found"
                    />
                    <img
                      src="assets/img/about/student-2.jpg"
                      alt="img not found"
                    />
                    <img
                      src="assets/img/about/student-1.jpg"
                      alt="img not found"
                    />
                  </Link>
                  <p>
                    Join over <span>4,000+</span> students
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 className="section__title">
                    Great for <br />
                    <span className="yellow-bg-big">
                      your Employees{" "}
                      {/* <img
                        src="assets/img/shape/yellow-bg-2.png"
                        alt="img not found"
                      /> */}
                    </span>{" "}
                    with Propelld Learning{" "}
                  </h2>
                  <p>
                    Learn better with specifically curated courses to assist you
                    in learning and development.
                  </p>
                </div>
                <div className="about__list mb-35">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </i>{" "}
                      Empowering employees to choose the course and learning
                      basis the budget,Interest, Business functions etc.
                    </li>
                    <li className="d-flex align-items-center">
                      <i>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </i>{" "}
                      Highly curated courses to choose from (With Learning
                      rating for every course to help them make well-informed
                      decisions.
                    </li>
                    <li className="d-flex align-items-center">
                      <i>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </i>{" "}
                      Financial wellness - Easy repayments @ zero rate of
                      interest on financing.
                    </li>
                    <li className="d-flex align-items-center">
                      <i>
                        <FontAwesomeIcon icon={["fas", "check"]} />
                      </i>{" "}
                      Learning support -Click to call feature to know more
                      details on the opted course
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="e-btn e-btn-border">
                  Apply now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
