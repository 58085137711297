import React from 'react'
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import SignUpVerification from '../../components/SignUp/SignUpVerification';

const SignUpVerificationPage = () => {
    return (
        <React.Fragment>
            <Header />
            <SignUpVerification />
            <Footer />
        </React.Fragment>
    )
}

export default SignUpVerificationPage