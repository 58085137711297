import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import Counter from '../Elements/Counter/CounterStyle'
import About from '../_home/AboutSection'

const AboutMain = () => {
  return (
    <main>
	            {/* breadcrumb-start */}
				<Breadcrumb pageTitle="About" />
				{/* breadcrumb-end */}

                {/* about-start */}
                <div className='mt-30'>
                    <About />
                </div>
				{/* about-end */}

                {/* counter-start */}
				<Counter />
				{/* counter-end */}

				
        	</main>
  )
}

export default AboutMain