import React from 'react'
import AboutMain from '../../components/About/AboutMain';
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";

const AboutPage = () => {
  return (
    <React.Fragment>
      <Header />
      <AboutMain />
      <Footer />
    </React.Fragment>
  )
}

export default AboutPage