import { notification } from "antd";
import { OrgService } from "../../services/org/OrgService";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";


export const addOrganisation = getMiddleware({
  actionType: types.ADD_ORG,
  onRequest: apiRequestHandler(OrgService.addOrgDetails),
  onSuccess: async ({ dispatch }) => {
    notification.success({ message: "Your organisation added successfully" })
    window.location.reload()
  },
});

export const getAllEmployees = getMiddleware({
  actionType: types.GET_ALL_EMPLOYEES,
  onRequest: apiRequestHandler(OrgService.getAllEmployees)
})

export const makeDeactivateEmployees = getMiddleware({
  actionType: types.MAKE_EMPLOYEE_INACTIVE,
  onRequest: apiRequestHandler(OrgService.deactivateEmployees),
  onSuccess: async ({ dispatch }) => {
    notification.success({ message: "Employee deactivated successfully" })
  },
})

export const makeActivateEmployees = getMiddleware({
  actionType: types.MAKE_EMPLOYEE_ACTIVE,
  onRequest: apiRequestHandler(OrgService.activateEmployees),
  onSuccess: async ({ dispatch }) => {
    notification.success({ message: "Employee activated successfully" })
  },
})


const middlewares = [
  addOrganisation,
  getAllEmployees,
  makeDeactivateEmployees,
  makeActivateEmployees
];

export default middlewares;