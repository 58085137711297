import React from 'react'
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import SignInVerification from '../../components/SignIn/SignInVerification';


const SignInVerificationPage = () => {
    return (
        <React.Fragment>
            <Header />
            <SignInVerification />
            <Footer />
        </React.Fragment>
    )
}

export default SignInVerificationPage