import React, { useState } from "react";
import { Skeleton, Switch, Card, Avatar } from "antd";

const { Meta } = Card;

export default () => {
  return (
    <>
      <Card style={{ width: 300, marginTop: 16 }}>
        <Skeleton loading={true} avatar active>
          <Meta
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title="Card title"
            description="This is the description"
          />
          <Meta
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title="Card title"
            description="This is the description"
          />
        </Skeleton>
        <Card
          style={{ marginTop: 16 }}
          bordered={false}
          type="inner"
          loading={true}
        >
          <Meta
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title="Card title"
            description="This is the description"
          />
        </Card>
      </Card>
    </>
  );
};
