import { createReducer } from "@reduxjs/toolkit";
import { actionSuccess } from "../helper";
import { actions as types } from "./actions";

export interface State {
    getBasicProf : {
        User: {
            FullName: string | null;
            Mobile: string | null;
            Email: string | null;
            CreatedAt: string | null;
            ActiveRole: string | null;
            SessionId: string | null;
            UserJourney: number | null;
        }
        OrgDetails : {
            OrgId: string | null;
            OrgName: string | null;
            IsVerified: boolean | null;
            IsAdmin: boolean | null;
            CreatedAt: string | null;
        }
    } ;
}

const initialState: State = {
    getBasicProf : {
        User: {
            FullName: null,
            Mobile: null,
            Email: null,
            CreatedAt: null,
            ActiveRole: null,
            SessionId: null,
            UserJourney: null
        },
        OrgDetails : {
            OrgId: null,
            OrgName: null,
            IsVerified: null,
            IsAdmin: null,
            CreatedAt: null
        }
    } 
};

export default createReducer(initialState, {
    [actionSuccess(types.GET_USER_BASIC_PROFILE)]: (state, action) => {
        console.log(action.payload)
        state.getBasicProf = action.payload;
    },

});