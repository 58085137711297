import baseUrls from "../../config/service"
import { NetworkRequest } from "../../types/NetworkRequest";
import NetworkRequestHelper from "../helper";

class ProfileService {

    static getProfileDetails(req: any): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.authentication + "/get-profile-details", req);
    }

}

export {
    ProfileService
}