import { notification } from "antd";
import { ProfileService } from "../../services/profile/ProfileService";
import Authenticator from "../../utils/authenticator";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";

export const profileDetailsFlow = getMiddleware({
    actionType: types.GET_USER_BASIC_PROFILE,
    onRequest: apiRequestHandler(ProfileService.getProfileDetails),
    onError: async ({ type, error, dispatch }) => {
          notification.error({ message: error.message });
          if(error.code === "SESSION_EXPIRED"){
              Authenticator.logOut();
          }
    },
})

const middlewares = [
    profileDetailsFlow
];

export default middlewares;