import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom'
import { actions, getUserBasicProfile } from '../../store/profile/actions';
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";

const ProfileMain = () => {

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getUserBasicProfile());
      }, []);

    return (
        <React.Fragment>
            <Header />
            <Outlet />
            <Footer />
        </React.Fragment>
    )
}

export default ProfileMain