// ApiUtils.js
import HttpStatusCode from '../types/HttpStatusCodes';
import ApiErrorHelper from '../types/ApiErrorHelper';
import { ServiceResponse } from '../types/ServiceResponse';

export let checkStatus = (response: Response) =>
{
    if (
        response.status === HttpStatusCode.OK ||
        response.status === HttpStatusCode.BAD_REQUEST ||
        response.status === HttpStatusCode.NOT_MODIFIED
    )
    {
        return response.json();
    }

    throw ApiErrorHelper.getErrorFromNetworkError(response);
};

export let checkResponseCode = (response: ServiceResponse) =>
{
    if (response.Code === 'SUCCESS')
    {
        return response;
    } else
    {
        throw ApiErrorHelper.getErrorFromServiceError(response);
    }
};
