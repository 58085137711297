const authentication = 'https://api.learning.propelld.com/auth';
const organisation = 'https://api.learning.propelld.com/org';
const employee = 'https://api.learning.propelld.com/employee';
const courses = 'https://api.learning.propelld.com/courses';

const baseUrls = {
    authentication,
    organisation,
    employee,
    courses
};

export default baseUrls;
