import { createAction } from "@reduxjs/toolkit";
import { ActivateEmployeeRequest } from "../../types/services/ActivateEmployee";
import { AddOrgRequest } from "../../types/services/AddOrg";
import { DeactivateEmployeeRequest } from "../../types/services/DeactivateEmployee";
import { actionRequest } from "../helper";


export const actions = {
    ADD_ORG: "ADD_ORG",
    GET_ALL_EMPLOYEES : "GET_ALL_EMPLOYEES",
    MAKE_EMPLOYEE_INACTIVE : "MAKE_EMPLOYEE_INACTIVE",
    MAKE_EMPLOYEE_ACTIVE : "MAKE_EMPLOYEE_ACTIVE",
};

export const addOrg = createAction<AddOrgRequest>(
    actionRequest(actions.ADD_ORG)
);

export const getAllEmployees = createAction(
    actionRequest(actions.GET_ALL_EMPLOYEES)
);

export const deactivateEmployee = createAction<DeactivateEmployeeRequest>(
    actionRequest(actions.MAKE_EMPLOYEE_INACTIVE)
);

export const activateEmployee = createAction<ActivateEmployeeRequest>(
    actionRequest(actions.MAKE_EMPLOYEE_ACTIVE)
);