export const actionRequest = (action: string) => {
  return action + "_REQUEST";
};

export const actionSuccess = (action: string) => {
  return action + "_SUCCESS";
};

export const actionFailed = (action: string) => {
  return action + "_FAILED";
};
