import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import antIcon from "../uielements/Spinner/spin";
import { connect, useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../uielements/validation";
import { actions, sessionLogin } from "../../store/auth/actions";
import { selectError, selectLoader } from "../../store/ui/selector";
import { RootState } from "../../config/store";
import { removeErrors } from "../../store/ui/actions";
import Authenticator from "../../utils/authenticator";
import { initialState } from "../../store/auth/reducer";

const SignInMain = (props: any) => {
  const [email, setEmail] = useState("");
  const [verify, setVerify] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((store: RootState) => store.auth);
  console.log(props, isLoggedIn);
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      return navigate("/dashboard/home");
    }
  }, [isLoggedIn]);

  const validate = () => {
    let emailError = "";

    if (!validateEmail(email)) {
      emailError = "Invalid Email";
    }

    if (emailError) {
      setEmailError(emailError);
      return false;
    }
    if (emailError === "") {
      setEmailError("");
      return true;
    }
    return true;
  };

  async function login(e:any) {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      if (["demo_org@propelld.com", "demo_emp@propelld.com"].includes(email)) {
        Authenticator.login("jnkjdkhb");
        initialState.isLoggedIn = true;
      } else {
        dispatch(removeErrors([actions.SESSION_LOGIN]));
        dispatch(sessionLogin({ email: email }));
        // dispatch(removeDoneActions([actions.SESSION_LOGIN]));
        // setDisabled(true);
        // setLoading(true);
        // console.log(email);
        // let item = { email };
        // let result = await fetch(
        //   `${process.env.REACT_APP_BASE_URL}/v1/auth/login-link`,
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Accept: "application/json",
        //     },
        //     body: JSON.stringify(item),
        //   }
        // );
        // result = await result.json();
        // setDisabled(false);
        // setLoading(false);
        // if (result.Code === "SUCCESS") {
        //   setVerify(true);
        // }
        // if (result.Code === "FAILED") {
        //   setError(result.Errors[0].Message);
        // }
        // // dispatch(session_login(result))
      }
    }
  }

  return (
    <main>
      <section className="signup__area po-rel-z1 pt-100 pb-145">
        <div className="sign__shape">
          <img
            className="man-1"
            src="/assets/img/icon/sign/man-1.png"
            alt="img not found"
          />
          <img
            className="man-2"
            src="/assets/img/icon/sign/man-2.png"
            alt="img not found"
          />
          <img
            className="circle"
            src="/assets/img/icon/sign/circle.png"
            alt="img not found"
          />
          <img
            className="zigzag"
            src="/assets/img/icon/sign/zigzag.png"
            alt="img not found"
          />
          <img
            className="dot"
            src="/assets/img/icon/sign/dot.png"
            alt="img not found"
          />
          <img
            className="bg"
            src="/assets/img/icon/sign/sign-up.png"
            alt="img not found"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
              <div className="section__title-wrapper text-center mb-55">
                <h2 className="section__title">
                  Sign in to <br /> propelld learning.
                </h2>
                <p>
                  it you don't have an account you can{" "}
                  <Link style={{ color: "blue" }} to="/sign-up">
                    Register here!
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div className="sign__wrapper white-bg">
                <div className="sign__form">
                  {!verify && (
                    <form action="#">
                      {/* {error !== "" && (
                        <div style={{ color: "red" }}>{error}</div>
                      )} */}
                      <div className="sign__input-wrapper mb-25">
                        <h5>Work email</h5>
                        <div className="sign__input">
                          <input 
                            type="text"
                            placeholder="e-mail address"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <i>
                            <FontAwesomeIcon icon={["fas", "envelope"]} />
                          </i>
                        </div>
                        {emailError || props.error ? (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {emailError || props.error[0].message}
                          </div>
                        ) : null}
                      </div>
                      <button
                        className="e-btn  w-100"
                        onClick={login}
                        disabled={disabled}
                      >
                        {" "}
                        <span></span> {props.isLoading && antIcon}{" "}
                        <span style={{ marginLeft: 5 }}>Sign In</span>{" "}
                      </button>
                      <div className="sign__new text-center mt-20">
                        <p>
                          New to Propelld Learning?{" "}
                          <Link to="/sign-up">
                            <a>Sign Up</a>
                          </Link>
                        </p>
                      </div>
                    </form>
                  )}
                  {verify && (
                    <div>
                      <h2 style={{ textAlign: "center" }}>Email Sent</h2>
                      Check your inbox! If we found your email address ({email})
                      you'll have a link to login and access your purchase
                      waiting for you.<br></br>
                      <br></br>
                      Sometimes this can land in SPAM! While we hope that isn't
                      the case if it doesn't arrive in a minute or three, please
                      check.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: selectLoader(actions.SESSION_LOGIN)(state),
    error: selectError(actions.SESSION_LOGIN)(state),
  };
};

export default connect(mapStateToProps)(SignInMain);
