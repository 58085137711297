import { useSelector } from "react-redux";
import { RootState } from "../config/store";
import { ResourceActions } from "../config/res_action/resourceActions";

const rolesPermission : {[name: string]: string[]} = {
    ORG_ADMIN: [ResourceActions.EmployeeManagement.ViewAll,
                ResourceActions.EmployeeManagement.Add,
                ResourceActions.EmployeeManagement.Edit,
                ResourceActions.EmployeeManagement.Delete,
                ResourceActions.CourseTab.ViewAll,
                ResourceActions.CourseTab.RemoveSelected
               ],
    ORG_EMPLOYEE: [ResourceActions.CourseTab.ViewSelected]
}

const usePermission = (permission: string) => {
  const { getBasicProf } = useSelector((store: RootState) => store.profile);
  const isAllowed = getBasicProf ? rolesPermission[(getBasicProf as any)?.User?.ActiveRole]?.includes(permission) : false;
  // const isAllowed = rolesPermission['ORG_ADMIN'].includes(permission);
  // console.log((getBasicProf as any).User.ActiveRole) 
  return [isAllowed]
};

export default usePermission;
