import auth from "./auth/middleware";
import ui from "./ui/middleware";
import api from "./api/middleware";
import profile from "./profile/middleware";
import org from "./org/middleware";
import emp from "./employee/middleware";
import course from "./course/middleware";

const rootmiddleware = [...api, ...auth, ...ui,...profile,...org,...emp,...course];

export default rootmiddleware;
