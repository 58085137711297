import { createReducer } from "@reduxjs/toolkit";
import { actions as types } from "./actions";
import { actionSuccess } from "../helper";
import Authenticator from "../../utils/authenticator";

export interface State {
  isLoggedIn: boolean;
}

export const initialState: State = {
  isLoggedIn: Authenticator.isLoggedIn(),
};

export default createReducer(initialState, {
  [actionSuccess(types.SESSION_LOGIN)]: (state, action) => {
    // Authenticator.login(action.payload);
    // state.isLoggedIn = true;
  },
  [actionSuccess(types.SESSION_LOGOUT)]: (state, action) => {
    Authenticator.logOut();
    state.isLoggedIn = false;
  },
  [actionSuccess(types.SIGN_UP)]: (state, action) => {
    // Authenticator.login(action.payload);
    // state.isLoggedIn = true;
  },
  [actionSuccess(types.VERIFY_EMAIL)]: (state, action) => {
    // console.log(action)
    Authenticator.login(action.payload.payload);
    state.isLoggedIn = true;
  },
  [actionSuccess(types.LOGIN_LINK)]: (state, action) => {
    Authenticator.login(action.payload);
    state.isLoggedIn = true;
  },

});
