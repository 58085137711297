import { createAction } from "@reduxjs/toolkit";
import { EmailVerificationRequest } from "../../types/services/EmailVerification";
import { LoginLinkRequest } from "../../types/services/LoginLink";
import { SessionLoginRequest } from "../../types/services/SessionLogin";
import { SignUpRequest } from "../../types/services/SignUp";
import { actionRequest } from "../helper";

export const actions = {
  SESSION_LOGIN: "SESSION_LOGIN",
  SESSION_LOGOUT: "SESSION_LOGOUT",
  SIGN_UP: "SIGN_UP",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  LOGIN_LINK: "LOGIN_LINK"
};

export const sessionLogin = createAction<SessionLoginRequest>(
  actionRequest(actions.SESSION_LOGIN)
);

export const sessionLogout = createAction(
  actionRequest(actions.SESSION_LOGOUT)
);

export const signUp = createAction<SignUpRequest>(
  actionRequest(actions.SIGN_UP)
);

export const verifyEmail = createAction<EmailVerificationRequest>(
  actionRequest(actions.VERIFY_EMAIL)
)

export const loginLink = createAction<LoginLinkRequest>(
  actionRequest(actions.LOGIN_LINK)
)
