import { createReducer } from "@reduxjs/toolkit";
import { actionSuccess } from "../helper";
import { actions as types } from "./actions";

export interface EmployeeModel {
    EmployeeId: string|null;
    FullName: string|null;
    Email: string|null;
    InviteStatus: boolean|null;
    Mobile: string | null;
    JoinedOn: string|null;
    IsActive: boolean|null;
}

export interface State {
    getAllEmp: EmployeeModel[];
}

const initialState: State = {
    getAllEmp: []
};

export default createReducer(initialState, {
    [actionSuccess(types.ADD_ORG)]: (state, action) => {
    
    },
    [actionSuccess(types.GET_ALL_EMPLOYEES)]: (state, action) => {
        console.log(action.payload)
        state.getAllEmp = action.payload.payload || [];
    },
    [actionSuccess(types.MAKE_EMPLOYEE_INACTIVE)]: (state, action) => {

    },
});