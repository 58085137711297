import { createAction } from "@reduxjs/toolkit";
import { SelectCourseRequest } from "../../types/services/SelectCourse";
import { actionRequest } from "../helper";

export const actions = {
    GET_ALL_COURSES: "GET_ALL_COURSES",
    GET_SELECTED_COURSES: "GET_SELECTED_COURSES",
    SELECT_COURSE: "SELECT_COURSE",
    DESELECT_COURSE: "DESELECT_COURSE",
};

export const getAllCourses = createAction(
    actionRequest(actions.GET_ALL_COURSES)
);

export const getSelectedCourses = createAction(
    actionRequest(actions.GET_SELECTED_COURSES)
);

export const selectCourse = createAction<SelectCourseRequest>(
    actionRequest(actions.SELECT_COURSE)
);
export const deSelectCourse = createAction<SelectCourseRequest>(
    actionRequest(actions.DESELECT_COURSE)
);