import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateEmail, validateMobile } from "../uielements/validation";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Skeleton, Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  actions,
  sentEmpInvitation,
  reSentEmpInvitation,
} from "../../store/employee/actions";
import { RootState } from "../../config/store";
import {
  selectDoneAction,
  selectError,
  selectLoader,
} from "../../store/ui/selector";
import antIcon from "../uielements/Spinner/spin";
import {
  actions as orgActions,
  getAllEmployees,
  deactivateEmployee,
  activateEmployee,
} from "../../store/org/actions";
import Restricted from "../Restricted/Restricted";
import { ResourceActions } from "../../config/res_action/resourceActions";
import { EmployeeModel } from "../../store/org/reducer";
import { removeDoneActions } from "../../store/ui/actions";

const EmployeesMain = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [mobError, setMobError] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [allEmployees, setAllEmployees] = useState([]);

  const { getAllEmp } = useSelector((store: RootState) => store.org);
  const { isLoggedIn } = useSelector((store: RootState) => store.auth);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(true);
    // setLoading(true);
  };

  const handleCancel = () => {
    setVisible(false);
    // setLoading(false);
    setName("");
    setEmail("");
  };

  useEffect(() => {
    if (props.isDoneInviteEmp) {
      setVisible(false);
      dispatch(getAllEmployees());
      dispatch(removeDoneActions([actions.SENT_EMPLOYEE_INVITATION]));
    }
  }, [props.isDoneInviteEmp]);

  const validate = () => {
    let nameError = "";
    let emailError = "";
    let mobileError = "";

    if (!name) {
      nameError = "Name cannot be blank";
    }

    if (!validateEmail(email)) {
      emailError = "Invalid Email";
    }

    if (!validateMobile(mobile)) {
      mobileError = "Invalid Mobile No";
    }

    if (emailError || nameError || mobileError) {
      setEmailError(emailError);
      setNameError(nameError);
      setMobError(mobileError);
      return false;
    }
    if (emailError === "" || nameError === "" || mobileError === "") {
      setEmailError("");
      setNameError("");
      setMobError("");
      return true;
    }
    return true;
  };

  // useEffect(() => {
  //     if (!isLoggedIn) {
  //       return navigate("/sign-in");
  //     }
  // }, [isLoggedIn]);

  async function invite_emp(e: any) {
    setVisible(true);
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      dispatch(sentEmpInvitation({ name: name, email: email, mobile: mobile }));
      console.log(name, email);
    }
  }

  async function re_invite_emp(e: any) {
    dispatch(
      reSentEmpInvitation({
        name: e.FullName,
        email: e.Email,
        mobile: e.Mobile,
      })
    );
    console.log(name, email);
  }

  function empInactive(id: string) {
    dispatch(deactivateEmployee({ EmployeeId: id }));
    dispatch(getAllEmployees());
  }

  function empActive(id: string) {
    dispatch(activateEmployee({ EmployeeId: id }));
    dispatch(getAllEmployees());
  }

  const ResendButton = (buttonProps: { emp: EmployeeModel }) => {
    return (
      <button
        className="e-btn e-btn-3 e-btn-4"
        onClick={() => re_invite_emp(buttonProps.emp)}
      >
        {props.isResndEmpLoading && antIcon}
        <span style={{ marginLeft: 10 }}>Re-Send Invitation</span>
      </button>
    );
  };
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  if (!props.isEmpLoading) {
    console.log(getAllEmp, "GET ALL EMP HERE");
  }
  // console.log(props.isEmpLoading)

  return (
    <>
      <main>
        {/* breadcrumb-start */}
        <Breadcrumb pageTitle="All Employees" />
        {/* breadcrumb-end */}

        <section className="cart-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <form action="#"> */}
                <div className="table-content table-responsive">
                  {props.isEmpLoading ? (
                    <Skeleton active />
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          {/* <th className="product-thumbnail">Photos</th> */}
                          <th className="cart-product-name">Name</th>
                          <th className="product-price">Mail Id</th>
                          <th className="product-remove">Status</th>
                          <th className="product-remove">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getAllEmp.map((emp: EmployeeModel, index) => {
                          return (
                            <tr key={index}>
                              <td>{emp.FullName}</td>
                              <td>{emp.Email}</td>
                              <td>
                                {emp.IsActive
                                  ? emp.InviteStatus
                                    ? "Joined On " +
                                      new Date(
                                        emp.JoinedOn as string
                                      ).toDateString()
                                    : "Not joined yet"
                                  : // <ResendButton emp={emp} />
                                    "Inactive"}
                              </td>
                              <td>
                                <Menu expandIcon={<></>}>
                                  <Menu.SubMenu
                                    key="SubMenu"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    icon={<SettingOutlined />}
                                  >
                                    {emp.IsActive ? (
                                      <Menu.Item
                                        key="two"
                                        onClick={() =>
                                          empInactive(emp.EmployeeId as string)
                                        }
                                      >
                                        Make employee inactive
                                      </Menu.Item>
                                    ) : (
                                      <Menu.Item
                                        key="two"
                                        onClick={() =>
                                          empActive(emp.EmployeeId as string)
                                        }
                                      >
                                        Make employee active
                                      </Menu.Item>
                                    )}
                                    {!emp.InviteStatus && (
                                      <ResendButton emp={emp} />
                                    )}
                                  </Menu.SubMenu>
                                </Menu>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="section__title-wrapper mt-40">
                  <Restricted to={ResourceActions.EmployeeManagement.Add}>
                    <Button type="primary" onClick={showModal}>
                      Add
                    </Button>
                  </Restricted>

                  <Modal
                    visible={visible}
                    title="Add Employees"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        Cancel
                      </Button>,
                      <Button key="submit" type="primary" onClick={invite_emp}>
                        {props.isLoading && antIcon}
                        <span style={{ marginLeft: 10 }}>Submit</span>
                      </Button>,
                    ]}
                  >
                    <form action="#">
                      <div className="sign__input-wrapper mb-25">
                        <h5>Full Name</h5>
                        <div className="sign__input">
                          <input
                            type="text"
                            id="name"
                            placeholder="Full name"
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                          <i>
                            <FontAwesomeIcon icon={["fas", "user"]} />
                          </i>
                        </div>
                        {nameError ? (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {nameError}
                          </div>
                        ) : null}
                      </div>
                      <div className="sign__input-wrapper mb-25">
                        <h5>Work email</h5>
                        <div className="sign__input">
                          <input
                            type="text"
                            id="email"
                            placeholder="e-mail address"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <i>
                            <FontAwesomeIcon icon={["fas", "envelope"]} />
                          </i>
                        </div>
                        {emailError ? (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {emailError}
                          </div>
                        ) : null}
                      </div>
                      <div className="sign__input-wrapper mb-25">
                        <h5>Mobile No</h5>
                        <div className="sign__input">
                          <input
                            type="text"
                            id="mobile"
                            placeholder="mobile-no"
                            onChange={(e) => setMobile(e.target.value)}
                          />
                          <i>
                            <FontAwesomeIcon icon={["fas", "phone"]} />
                          </i>
                        </div>
                        {mobError ? (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {mobError}
                          </div>
                        ) : null}
                      </div>
                    </form>
                  </Modal>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <Outlet /> */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: selectLoader(actions.SENT_EMPLOYEE_INVITATION)(state),
    isDoneInviteEmp: selectDoneAction(actions.SENT_EMPLOYEE_INVITATION)(state),
    isEmpLoading: selectLoader(orgActions.GET_ALL_EMPLOYEES)(state),
    isResndEmpLoading: selectLoader(actions.RESENT_EMPLOYEE_INVITATION)(state),
    error: selectError(actions.SENT_EMPLOYEE_INVITATION)(state),
  };
};

export default connect(mapStateToProps)(EmployeesMain);
