import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { RootState } from "../../../config/store";
import { connect, useDispatch, useSelector } from "react-redux";
import { CourseModel } from "../../../store/course/reducer";
import { Modal, Skeleton, Spin } from "antd";
import {
  actions,
  deSelectCourse,
  getSelectedCourses,
} from "../../../store/course/actions";
import {
  selectDoneAction,
  selectError,
  selectLoader,
} from "../../../store/ui/selector";
import antIcon from "../../uielements/Spinner/spin";
import Restricted from "../../Restricted/Restricted";
import { ResourceActions } from "../../../config/res_action/resourceActions";
import CardSkeleton from "../../uielements/CardSkeleton/CardSkeleton";
import { addDoneActions, removeDoneActions } from "../../../store/ui/actions";

function MyCoursesTab(props: any) {
  const { slectedCourses } = useSelector((store: RootState) => store.course);
  const dispatch = useDispatch();

  function titleCase(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }

  useEffect(() => {
    if (props.isDoneDeselectCourse) {
      dispatch(getSelectedCourses());
      dispatch(removeDoneActions([actions.DESELECT_COURSE]));
    }
  }, [props.isDoneDeselectCourse]);

  function deSelect(course: CourseModel) {
    dispatch(deSelectCourse({ CourseId: course.CourseId as string }));
    // if () {
    //   dispatch(getSelectedCourses());
    // }
  }

  return (
    <div className="row">
      {props.isDeSelectLoading && (
        <Modal
          centered
          visible={props.isDeSelectLoading}
          title="Deleting Course"
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: true }}
          footer={[<></>]}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin size="large" tip="Loading..." />
          </div>
        </Modal>
      )}
      {props.isLoading ? (
        <>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <CardSkeleton />
          </div>
        </>
      ) : (
        slectedCourses.map((course: CourseModel, index: number) => {
          return (
            <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="course__item white-bg mb-30 fix">
                <div className="course__thumb w-img p-relative fix">
                  <Link to="/course-details" state={{ selectedCourse: course }}>
                    <img
                      src={course.Thumbnail as string}
                      loading="lazy"
                      alt="img not found"
                    />
                  </Link>
                  <div className="course__tag">
                    <Link to="/course-details">
                      <a className="purple">
                        {course.Category?.map((cat: string) =>
                          titleCase(cat)
                        ).join(", ")}
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="course__content">
                  <div className="course__meta d-flex align-items-center justify-content-between">
                    <div className="course__lesson">
                      <span>
                        <i>
                          <FontAwesomeIcon icon={["fas", "book"]} />
                        </i>
                        {course.Duration} Lessons
                      </span>
                    </div>
                    <div className="course__rating">
                      <span>
                        <i>
                          <FontAwesomeIcon icon={["fas", "star"]} />
                        </i>
                        {course.Rating}
                      </span>
                    </div>
                  </div>
                  <h3 className="course__title">
                    <Link
                      to="/course-details"
                      state={{ selectedCourse: course }}
                    >
                      {course.CourseName?.substring(0, 50)}...
                    </Link>
                  </h3>
                  <div className="course__teacher d-flex align-items-center">
                    <div className="course__teacher-thumb mr-15">
                      <img
                        src={course.InstituteLogo as string}
                        alt="img not found"
                      />
                    </div>
                    <h6>
                      <Link to="/instructor-details">
                        <a>{titleCase(course.OfferedBy as string)}</a>
                      </Link>
                    </h6>
                  </div>
                </div>
                <Restricted to={ResourceActions.CourseTab.RemoveSelected}>
                  <div className="course__more d-flex justify-content-between align-items-center">
                    <div className="course__btn w-100">
                      <button
                        style={{ backgroundColor: "#e41b23" }}
                        className="e-btn  w-100"
                        onClick={() => deSelect(course as CourseModel)}
                      >
                        {/* {props.isDeSelectLoading && antIcon} */}
                        Remove this course for employees
                      </button>
                    </div>
                  </div>
                </Restricted>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: selectLoader(actions.GET_SELECTED_COURSES)(state),
    isDeSelectLoading: selectLoader(actions.DESELECT_COURSE)(state),
    isDoneDeselectCourse: selectDoneAction(actions.DESELECT_COURSE)(state),
    error: selectError(actions.GET_SELECTED_COURSES)(state),
  };
};

export default connect(mapStateToProps)(MyCoursesTab);
