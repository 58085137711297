import React from 'react'
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import DashboardMain from '../../components/Dashboard/DashboardMain';

const DashboardPage = () => {
    return (
        <React.Fragment>
            {/* <Header /> */}
            <DashboardMain />
            {/* <Footer /> */}
        </React.Fragment>
    )
}

export default DashboardPage