import baseUrls from "../../config/service"
import { NetworkRequest } from "../../types/NetworkRequest";
import { ActivateEmployeeRequest } from "../../types/services/ActivateEmployee";
import { DeactivateEmployeeRequest } from "../../types/services/DeactivateEmployee";
import NetworkRequestHelper from "../helper";

class OrgService {

    static addOrgDetails(req: any): NetworkRequest
    {
        return NetworkRequestHelper.POST(baseUrls.organisation + "/update-org-details", req);
    }

    static getAllEmployees(req: any): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.organisation + "/get-all-employees", req);
    }

    static deactivateEmployees(req: DeactivateEmployeeRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.organisation + "/deactivate-employee", req);
    }
    
    static activateEmployees(req: ActivateEmployeeRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.organisation + "/activate-employee", req);
    }

}

export {
    OrgService
}