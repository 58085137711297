import React from "react";
import { Routes, Route } from "react-router-dom";
import ContactPage from "./contact";
import HomePage from "./home";
import SignInPage from "./signIn";
import SignUpPage from "./signUp";
import ProtectedRoute from "./protectedRoutes";
import { loadIcons } from "../utils/IconLoader";
import ErrorPage from "./error";
import AboutPage from "./about";
import SignUpVerificationPage from "./signUpVerification";
import SignInVerificationPage from "./signInVerification";
import EmployeePage from "./employees";
import CourseDetailsPage from "./courseDetails";
import DashboardPage from "./dashboard";
import WhyusPage from "./why-us";
import ScrollToTop from "../components/common/ScrollToTop";
import OrgDetailsPage from "./org-details";
import ProfileMain from "../components/Profile/ProfileMain";

loadIcons();

const Root = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<ProfileMain />}>
            <Route path="home" element={<DashboardPage />} />
            <Route path="employee-management" element={<EmployeePage />} />
          </Route>
        </Route>

        <Route path="/contact" element={<ContactPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route
          path="/verify-email/:txnId/:token"
          element={<SignUpVerificationPage />}
        />
        <Route
          path="/sign-in/:txnId/:token"
          element={<SignInVerificationPage />}
        />
        <Route path="/about" element={<AboutPage />} />
        {/* <Route path="employees" element={<EmployeePage />} />  */}
        <Route path="/course-details" element={<CourseDetailsPage />} />
        <Route path="/org-details" element={<OrgDetailsPage />} />
        <Route path="/why-us" element={<WhyusPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Root;
