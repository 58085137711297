export function validateMobile(input_str:string) {
    var re = /^[2-9]{1}[0-9]{9}$/;
  
    return re.test(input_str);
}
export function validateEmail(input_str:string) {
    var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  
    return re.test(input_str);
}

