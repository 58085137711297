import React, { useEffect, useState } from 'react'
import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { session_login } from '../../redux/actions/actions';
import {actions, loginLink } from '../../store/auth/actions';
import { RootState } from '../../config/store';
import { selectError, selectLoader } from '../../store/ui/selector';

const SignInVerification = (props:any) => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const { isLoggedIn } = useSelector((store: RootState) => store.auth);
    const { txnId, token } = useParams();


    const cntd = <LoadingOutlined style={{ fontSize: 89, color: 'blue' }} spin />;

    const right = <CheckCircleOutlined style={{ fontSize: 89, color: 'green' }} />;

    const wrong = <CloseCircleOutlined style={{ fontSize: 89, color: 'red' }} />;

    useEffect(() => {
          if (isLoggedIn) {
            return navigate("/dashboard/home");
          }
        }, [isLoggedIn]);

    console.log(props.error, props.isLoading)

    // Hit the backend api to verify email !

    useEffect( () => {
        dispatch(loginLink({TransactionId: txnId as string, Token: token as string}))

        // try {
        //     let loginStatus = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/auth/validate-login/${txnId}/${token}`);
        //     loginStatus = await loginStatus.json();
        //     console.log(loginStatus)
        //     if (loginStatus.Code === 'SUCCESS') {
        //         const jwt = loginStatus.PayLoad
        //         dispatch(session_login(jwt))
        //         setSuccessStatus(true);
        //         navigate("/dashboard");
        //     }
        //     if (loginStatus.Code === 'FAILED'){
        //         setErrorStatus(loginStatus.Errors[0].Message)
        //     }
        // }
        // catch(e){
        //     setErrorStatus(e);
        // }
        
    }, [])

    return (
        <main>
            <section className="signup__area po-rel-z1 pt-100 pb-145">
                <div className="sign__shape">
                    {/* <img className="man-1" src="assets/img/icon/sign/man-3.png" alt="img not found" />
                    <img className="man-2 man-22" src="assets/img/icon/sign/man-2.png" alt="img not found" /> */}
                    <img className="circle" src="/assets/img/icon/sign/circle.png" alt="img not found" />
                    <img className="zigzag" src="/assets/img/icon/sign/zigzag.png" alt="img not found" />
                    <img className="dot" src="/assets/img/icon/sign/dot.png" alt="img not found" />
                    <img className="bg" src="/assets/img/icon/sign/sign-up.png" alt="img not found" />
                    <img className="flower" src="/assets/img/icon/sign/flower.png" alt="img not found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                            <div className="section__title-wrapper text-center mb-55">
                                <h2 className="section__title">Logging you in</h2>
                                <p>I'm a subhead that goes with a story.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="sign__wrapper white-bg">
                                <div className="sign__form">
                                    {props.isLoading && !props.error && (<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        {cntd}<br></br><br></br>
                                        <span style={{ fontSize: 30, textAlign:'center' }}>Please wait...</span>
                                    </div>)}
                                    {props.error && (<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        {wrong}<br></br><br></br>
                                        <span style={{ fontSize: 30,textAlign:'center' }}>{props.error[0].message}</span>
                                    </div>)}
                                    {!props.isLoading && !props.error &&(<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        {right}<br></br><br></br>
                                        <span style={{ fontSize: 30, textAlign:'center' }}>Logged In</span>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
const mapStateToProps = (state: RootState) => {
    return {
      isLoading: selectLoader(actions.LOGIN_LINK)(state),
      error: selectError(actions.LOGIN_LINK)(state),
    };
  };

export default connect(mapStateToProps)(SignInVerification)