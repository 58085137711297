import React from "react";
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import OrgMain from "../../components/Org-Details/OrgMain";

const OrgDetailsPage = () => {
    return (
        <React.Fragment>
            <Header />
            <OrgMain />
            <Footer />
        </React.Fragment>
    );
};

export default OrgDetailsPage;
