import { createAction } from "@reduxjs/toolkit";
import { ReSentEmployeeInviteRequest } from "../../types/services/ReSentEmpInviteLink";
import { EmployeeInviteRequest } from "../../types/services/sentEmpInviteLink";
import { actionRequest } from "../helper";

export const actions = {
    SENT_EMPLOYEE_INVITATION : "SENT_EMPLOYEE_INVITATION",
    RESENT_EMPLOYEE_INVITATION : "RESENT_EMPLOYEE_INVITATION"
    
};

export const sentEmpInvitation = createAction<EmployeeInviteRequest>(
    actionRequest(actions.SENT_EMPLOYEE_INVITATION)
);
export const reSentEmpInvitation = createAction<ReSentEmployeeInviteRequest>(
    actionRequest(actions.RESENT_EMPLOYEE_INVITATION)
);

