import React, { useState, useEffect } from "react";
import { Link,Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BurgerMenus from "./BurgerMenus";
import { useDispatch, useSelector } from "react-redux";
import { sessionLogout } from "../../../store/auth/actions";
import { RootState } from "../../../config/store";
import Restricted from "../../Restricted/Restricted";
import { ResourceActions } from "../../../config/res_action/resourceActions";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useLocation();
  const [path, setPath] = useState("");
  const { isLoggedIn } = useSelector((store: RootState) => store.auth);
  const { getBasicProf } = useSelector((store: RootState) => store.profile);

  useEffect(() => {
    setPath(router.pathname);
  }, [router]);

  // Sticky Menu Area start
  useEffect(() => {
    window.addEventListener("scroll", sticky);
    return () => {
      window.removeEventListener("scroll", sticky);
    };
  });

  const sticky = (e: any) => {
    const header: any = document.querySelector(".header__area");
    const scrollTop = window.scrollY;
    scrollTop >= 1
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };
  // Sticky Menu Area End

  function logout() {
    dispatch(sessionLogout());
  }

  // const login = () => {
  //   dispatch(session_login())
  // }

  return (
    <React.Fragment>
      <header>
        <div id="header-sticky" className="header__area header__padding">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                <div className="header__left d-flex">
                  <div className="logo">
                    <Link to="/">
                      <img
                        height={36}
                        src="/assets/img/logo/logo.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                <div className="header__right d-flex justify-content-end align-items-center">
                  <div className="main-menu d-none d-xl-block">
                    <nav id="mobile-menu">
                      <ul>
                        {isLoggedIn && (
                          <>
                            {" "}
                            <li>
                              <Link to="/dashboard/home">Dashboard</Link>
                            </li>
                            <Restricted to={ResourceActions.EmployeeManagement.ViewAll}>
                              <li>
                                <Link to="/dashboard/employee-management">Employees</Link>
                              </li>
                            </Restricted>
                            <li onClick={logout}>
                              <Link to="/sign-in">Sign Out</Link>
                            </li>
                          </>
                        )}
                        {/* {getBasicProf && (getBasicProf as any).User  && <li>
                          <Link to="/dashboard">
                            <span style={{color:"blue"}}>Welcome!</span> {(getBasicProf as any).User.FullName}
                          </Link>
                        </li>} */}
                        {!isLoggedIn && (
                          <>
                            <li>
                              <Link to="/why-us">Why us</Link>
                            </li>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                              <Link to="/sign-in">Sign In</Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </nav>
                  </div>

                  <div className="header__btn ml-20 d-none d-sm-block">
                    <Link to="/contact" className="e-btn">
                      We'll love to onboard you
                    </Link>
                  </div>
                  <div className="sidebar__menu d-xl-none">
                    <div
                      className="sidebar-toggle-btn ml-30"
                      id="sidebar-toggle"
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BurgerMenus menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div
          onClick={() => setMenuOpen(false)}
          className={menuOpen ? "body-overlay show" : "body-overlay"}
        ></div>

        <div
          onClick={() => setShopOpen(false)}
          className={shopOpen ? "body-overlay show" : "body-overlay"}
        ></div>
      </header>
    </React.Fragment>
  );
};

export default Header;
