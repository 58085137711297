import React from "react";
import EmployeesMain from "../../components/Employees/EmployeesMain";
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";

const EmployeePage = () => {
    return (
        <React.Fragment>
            {/* <Header /> */}
            <EmployeesMain />
            {/* <Footer /> */}
        </React.Fragment>
    );
};

export default EmployeePage;
