import auth from "./auth/reducer";
import ui from "./ui/reducer";
import profile from "./profile/reducer";
import org from "./org/reducer";
import course from "./course/reducer";

const rootReducer = {
  auth,
  ui,
  profile,
  org,
  course
};

export default rootReducer;
