import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectError, selectLoader } from "../../store/ui/selector";
import antIcon from "../uielements/Spinner/spin";
import { RootState } from '../../config/store';
import { connect, useDispatch } from 'react-redux';
import { actions,addOrg } from '../../store/org/actions';
import { useNavigate } from 'react-router';

const OrgMain = (props:any) => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const dispatch = useDispatch();
    let navigate = useNavigate();

    // useEffect(() => {
    //     if (!props.isLoading) {
    //       return navigate("/admin-dashboard");
    //     }
    //   }, [props.isLoading]);

    const validate = () => {
        let nameError = "";

        if (!name) {
            nameError = "Name cannot be blank"
        }

        if (nameError) {
            setNameError(nameError)
            return false
        }
        if (nameError === "") {
            setNameError("")
            return true
        }
        return true;
    }

    async function add_org(e: any) {
        e.preventDefault();
        const isValid = validate();
        if (isValid) {
            dispatch(addOrg({orgName: name}))
            console.log(name);
        }
        
    }

    return (
        <main>
            <section className="signup__area po-rel-z1 pt-100 pb-145">
                <div className="sign__shape">
                    <img
                        className="man-1"
                        src="/assets/img/icon/sign/man-1.png"
                        alt="img not found"
                    />
                    <img
                        className="man-2"
                        src="/assets/img/icon/sign/man-2.png"
                        alt="img not found"
                    />
                    <img
                        className="circle"
                        src="/assets/img/icon/sign/circle.png"
                        alt="img not found"
                    />
                    <img
                        className="zigzag"
                        src="/assets/img/icon/sign/zigzag.png"
                        alt="img not found"
                    />
                    <img
                        className="dot"
                        src="/assets/img/icon/sign/dot.png"
                        alt="img not found"
                    />
                    <img
                        className="bg"
                        src="/assets/img/icon/sign/sign-up.png"
                        alt="img not found"
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                            <div className="section__title-wrapper text-center mb-55">
                                <h2 className="section__title">
                                    Add your <br /> organisation details
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="sign__wrapper white-bg">
                                <div className="sign__form">
                                    <form action="#">
                                        <div className="sign__input-wrapper mb-25">
                                            <h5>Organisation Name</h5>
                                            <div className="sign__input">
                                                <input
                                                    type="text"
                                                    placeholder="organisation name"
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                />
                                                <i>
                                                    <FontAwesomeIcon icon={["fas", "building"]} />
                                                </i>
                                            </div>
                                            {nameError ? (<div style={{ fontSize: 12, color: 'red' }}>{nameError}</div>) : null}
                                        </div>
                                        <button
                                            className="e-btn  w-100"
                                            onClick={add_org}
                                        // disabled={disabled}
                                        >
                                            {" "}
                                            <span></span> {props.isLoading && antIcon}{" "}
                                            <span style={{ marginLeft: 5 }}>Add</span>{" "}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: selectLoader(actions.ADD_ORG)(state),
        error: selectError(actions.ADD_ORG)(state),
    };
};

export default connect(mapStateToProps)(OrgMain);