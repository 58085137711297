import baseUrls from "../../config/service";
import { NetworkRequest } from "../../types/NetworkRequest";
import { DeSelectCourseRequest } from "../../types/services/DeSelectCourse";
import { SelectCourseRequest } from "../../types/services/SelectCourse";
import NetworkRequestHelper from "../helper";

class CourseService {

    static getAllCourses(): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.courses + "/get-all");
    }
    static getSelectedCourses(): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.courses + "/get-selected");
    }
    static selectCourse(req: SelectCourseRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.courses + "/select", req);
    }
    static deSelectCourse(req: DeSelectCourseRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.courses + "/deselect-course", req);
    }
}

export {
    CourseService
}