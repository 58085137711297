import { NetworkRequest } from "../../types/NetworkRequest";
import { SessionLoginRequest } from "../../types/services/SessionLogin";
import { SignUpRequest } from "../../types/services/SignUp";
import NetworkRequestHelper from "../helper";
import baseUrls from "../../config/service"
import { EmailVerificationRequest } from "../../types/services/EmailVerification";
import { LoginLinkRequest } from "../../types/services/LoginLink";

class AuthService {

    static sendLoginLink(req: SessionLoginRequest): NetworkRequest
    {
        return NetworkRequestHelper.POST(baseUrls.authentication + "/login-link", req);
    }
    static sendSignUpLink(req: SignUpRequest): NetworkRequest
    {
        return NetworkRequestHelper.POST(baseUrls.authentication + "/signup", req);
    }
    static emailVerification(req: EmailVerificationRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.authentication + "/verify-email", req);
    }
    static verifyLoginLink(req: LoginLinkRequest): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.authentication + "/validate-login", req);
    }
    static sendLogout(req: any): NetworkRequest
    {
        return NetworkRequestHelper.GET(baseUrls.authentication + "/logout", req);
    }
}

export {
    AuthService
}