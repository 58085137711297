import env from "../../env.json";
import serviceDev from './service.dev';
import serviceProd from './service.prod';

let config = serviceDev;

if(env.ENV === "live")
{
    config = serviceProd;
}

export default config;