import { AnyAction, Dispatch, Middleware } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { NetworkRequest } from "../types/NetworkRequest";
import { apiRequest } from "./api/actions";
import { actionFailed, actionRequest, actionSuccess } from "./helper";
import { addDoneActions, hideSpinner, showSpinner } from "./ui/actions";

type HandlerOptions = {
  dispatch: Dispatch<AnyAction>;
  getState: () => any;
  action: any;
  payload: any;
  type: any;
  error?: any;
};

type Handler = (options: HandlerOptions) => Promise<any>;

type MiddlewareOptions = {
  actionType: string;
  onRequest?: Handler;
  onSuccess?: Handler;
  onError?: Handler;
};

export const apiRequestHandler =
  (networkRequestHandler: (actionPayload: any) => NetworkRequest): Handler =>
  async (opt) => {
    const networkReq = networkRequestHandler(opt.payload);
    opt.dispatch(
      apiRequest(networkReq, actionSuccess(opt.type), actionFailed(opt.type))
    );
  };

export const getMiddleware =
  (opts: MiddlewareOptions): Middleware =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const handlerOptions: HandlerOptions = {
      dispatch,
      getState,
      action,
      type: opts.actionType,
      payload: action.payload,
    };
    if (action.type === actionRequest(opts.actionType)) {
      dispatch(showSpinner(opts.actionType));

      if (opts.onRequest) {
        await opts.onRequest(handlerOptions);
      }
    }

    if (action.type === actionSuccess(opts.actionType)) {
      dispatch(hideSpinner(opts.actionType));
      try {
        if (opts.onSuccess) {
          await opts.onSuccess(handlerOptions);
        }
        dispatch(addDoneActions(opts.actionType));
      } catch (e) {
        console.log(e);
        notification.error({ message: "Unexpected Error Occured!" });
      }
    }
    if (action.type === actionFailed(opts.actionType)) {
      dispatch(hideSpinner(opts.actionType));
      if (opts.onError) {
        let error = null;
        try {
          const hasError = action.payload?.type === "ServiceError";
          if (hasError) {
            error = action.payload.errors[0];
          }
        } catch (e) {
          error = {
            code: "UnexpectedError",
            message: "Unexpected Error Occured!",
          };
        }
        await opts.onError({ ...handlerOptions, error });
      } else {
        try {
          const hasError = action.payload?.type === "ServiceError";
          if (hasError) {
            const firstError = action.payload.errors[0];
            notification.error({ type: "error", message: firstError.message });
          }
        } catch (e) {
          console.log(e);
          notification.error({ message: "Unexpected Error Occured!" });
        }
      }
    }
  };
