import React from "react";
import usePermission from "../../hooks/usePermission";

type Props = {
  to: string;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
};

const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  children,
  ...restProps
}) => {
  const [allowed] = usePermission(to);

  if (allowed) {
    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, restProps);
      }
      return child;
    });
    return <>{restProps ? childrenWithProps : children}</>;
  }

  return <>{fallback}</>;
};

export default Restricted;
