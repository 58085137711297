import { notification } from "antd";
import { AuthService } from "../../services/auth/AuthService";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { getUserBasicProfile } from "../profile/actions";
import { showError } from "../ui/actions";
import { actions as types } from "./actions";


export const sessionLoginFlow = getMiddleware({
  actionType: types.SESSION_LOGIN,
  onRequest: apiRequestHandler(AuthService.sendLoginLink),
  onSuccess: async ({dispatch}) => {
    notification.success({message: "Email has been sent to your given mail ."})
  },
  onError: async ({ type, error, dispatch }) => {
    if (error.code === "USER_DOESNT_EXIST") {
      dispatch(showError("medium", error.message, "", type));
    } else {
      notification.error({ message: error.message });
    }
  },
});

export const sessionLogoutFlow = getMiddleware({
  actionType: types.SESSION_LOGOUT,
  onRequest: apiRequestHandler(AuthService.sendLogout),
});

export const signUpFlow = getMiddleware({
  actionType: types.SIGN_UP,
  onRequest: apiRequestHandler(AuthService.sendSignUpLink),
  onSuccess: async ({dispatch}) => {
    notification.success({message: "Email has been sent to your given mail ."})
  },
});

export const emailVerificationFlow = getMiddleware({
  actionType: types.VERIFY_EMAIL,
  onRequest: apiRequestHandler(AuthService.emailVerification),
  onError: async ({ type, error, dispatch }) => {
    dispatch(showError("medium", error.message, "", type));
  },
});

export const loginLinkFlow = getMiddleware({
  actionType: types.LOGIN_LINK,
  onRequest: apiRequestHandler(AuthService.verifyLoginLink),
  onError: async ({ type, error, dispatch }) => {
    dispatch(showError("medium", error.message, "", type));
  },
})

const middlewares = [
  sessionLoginFlow,
  signUpFlow,
  emailVerificationFlow,
  loginLinkFlow,
  sessionLogoutFlow
];

export default middlewares;
