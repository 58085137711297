import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import antIcon from '../uielements/Spinner/spin';
import { validateEmail, validateMobile } from '../uielements/validation';
import { connect, useDispatch } from 'react-redux';
import { removeErrors } from '../../store/ui/actions';
import { actions, signUp } from "../../store/auth/actions";
import { RootState } from '../../config/store';
import { selectError, selectLoader } from '../../store/ui/selector';
const SignUpMain = (props: any) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [verify, setVerify] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [mobError, setMobError] = useState("");
    const [nameError, setNameError] = useState("");
    const dispatch = useDispatch();

    const validate = () => {
        let nameError = "";
        let emailError = "";
        let mobileError = "";

        if (!name) {
            nameError = "Name cannot be blank"
        }

        if (!validateEmail(email)) {
            emailError = "Invalid Email";
        }

        if (!validateMobile(mobile)) {
            mobileError = "Invalid Mobile No"
        }

        if (emailError || nameError || mobileError) {
            setEmailError(emailError)
            setNameError(nameError)
            setMobError(mobileError)
            return false
        }
        if (emailError === "" || nameError === "" || mobileError === "") {
            setEmailError("")
            setNameError("")
            setMobError("")
            return true
        }
        return true;
    }

    async function sign_Up(e: any) {
        e.preventDefault();
        const isValid = validate();
        if (isValid) {
            dispatch(removeErrors([actions.SIGN_UP]));
            dispatch(signUp({ name: name, email: email, mobile: mobile }));
            // setDisabled(true);
            // setLoading(true);
            // console.log(name, email, mobile);
            // let item = { name, email, mobile }
            // let result = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/auth/signup`, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Accept": "application/json"
            //     },
            //     body: JSON.stringify(item)
            // });
            // result = await result.json();
            // // console.log(result);
            // setDisabled(false);
            // setLoading(false);
            // result.Code === "SUCCESS" ? setVerify(true) : setError(result.Errors[0].Message);
            // console.log(result)
        }

    }

    return (
        <main>
            <section className="signup__area po-rel-z1 pt-100 pb-145">
                <div className="sign__shape">
                    <img className="man-1" src="assets/img/icon/sign/man-3.png" alt="img not found" />
                    <img className="man-2 man-22" src="assets/img/icon/sign/man-2.png" alt="img not found" />
                    <img className="circle" src="assets/img/icon/sign/circle.png" alt="img not found" />
                    <img className="zigzag" src="assets/img/icon/sign/zigzag.png" alt="img not found" />
                    <img className="dot" src="assets/img/icon/sign/dot.png" alt="img not found" />
                    <img className="bg" src="assets/img/icon/sign/sign-up.png" alt="img not found" />
                    <img className="flower" src="assets/img/icon/sign/flower.png" alt="img not found" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                            <div className="section__title-wrapper text-center mb-55">
                                <h2 className="section__title">Create a free <br />  Account</h2>
                                <p>I'm a subhead that goes with a story.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div className="sign__wrapper white-bg">
                                {/* <div className="sign__header mb-35">
                                    <div className="sign__in text-center">
                                        <a href="#" className="sign__social g-plus text-start mb-15"><i><FontAwesomeIcon icon={['fab', 'google']} /></i>Sign Up with Google</a>
                                        <p> <span>........</span> Or, <Link to="/sign-up"><a>sign up</a></Link> with your email<span> ........</span> </p>
                                    </div>
                                </div> */}
                                <div className="sign__form">
                                    {!verify && (
                                        <form action="#">
                                            <div className="sign__input-wrapper mb-25">
                                                <h5>Full Name</h5>
                                                <div className="sign__input">
                                                    <input type="text"
                                                        id="name"
                                                        placeholder="Full name"
                                                        onChange={(e) => setName(e.target.value)} required />
                                                    <i><FontAwesomeIcon icon={['fas', 'user']} /></i>
                                                </div>
                                                {nameError ? (<div style={{ fontSize: 12, color: 'red' }}>{nameError}</div>) : null}
                                            </div>
                                            <div className="sign__input-wrapper mb-25">
                                                <h5>Work email</h5>
                                                <div className="sign__input">
                                                    <input type="text"
                                                        id="email"
                                                        placeholder="e-mail address"
                                                        onChange={(e) => setEmail(e.target.value)} required />
                                                    <i><FontAwesomeIcon icon={['fas', 'envelope']} /></i>

                                                </div>
                                                {emailError ? (<div style={{ fontSize: 12, color: 'red' }}>{emailError}</div>) : null}
                                            </div>
                                            <div className="sign__input-wrapper mb-25">
                                                <h5>Mobile No</h5>
                                                <div className="sign__input">
                                                    <input type="text"
                                                        id="mobile"
                                                        placeholder="mobile-no"
                                                        onChange={(e) => setMobile(e.target.value)} />
                                                    <i><FontAwesomeIcon icon={['fas', 'phone']} /></i>

                                                </div>
                                                {mobError ? (<div style={{ fontSize: 12, color: 'red' }}>{mobError}</div>) : null}
                                            </div>
                                            {/* <div className="sign__action d-flex justify-content-between mb-30">
                                                <div className="sign__agree d-flex align-items-center">
                                                    <input className="m-check-input" type="checkbox" id="m-agree" />
                                                    <label className="m-check-label" htmlFor="m-agree">I agree to the <a href="#">Terms & Conditions</a>
                                                    </label>
                                                </div>
                                            </div> */}
                                            <button className="e-btn w-100" onClick={sign_Up} disabled={disabled} > <span></span>
                                                {props.isLoading && antIcon}
                                                <span style={{ marginLeft: 10 }}>Sign Up</span></button>
                                            <div className="sign__new text-center mt-20">
                                                <p>Already in Markit ? <Link to="/sign-in"><a>Sign In</a></Link></p>
                                            </div>
                                        </form>
                                    )}
                                    {verify && (
                                        <div>
                                            {/* <strong>A verfication link is sent to your mail .</strong>
                                            <p style={{ marginTop: 45 }}><strong>If you not get just click on the resend .</strong>
                                                <button style={{ marginTop: 15 }} className="e-btn w-75">Resend Verification Link </button>
                                            </p> */}
                                            <h2 style={{ textAlign: 'center' }}>Email Sent</h2>
                                            Check your inbox! If we found your email address ({email}) you'll have a link to login and access your purchase waiting for you.<br></br><br></br>

                                            Sometimes this can land in SPAM! While we hope that isn't the case if it doesn't arrive in a minute or three, please check.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: selectLoader(actions.SIGN_UP)(state),
        error: selectError(actions.SIGN_UP)(state),
    };
};

export default connect(mapStateToProps)(SignUpMain);