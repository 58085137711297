import baseUrls from "../../config/service";
import { NetworkRequest } from "../../types/NetworkRequest";
import { ReSentEmployeeInviteRequest } from "../../types/services/ReSentEmpInviteLink";
import { EmployeeInviteRequest } from "../../types/services/sentEmpInviteLink";
import NetworkRequestHelper from "../helper";

class EmployeeService {

    static sendEmpInvitepLink(req: EmployeeInviteRequest): NetworkRequest
    {
        return NetworkRequestHelper.POST(baseUrls.employee + "/invite", req);
    }
    static reSendEmpInvitepLink(req: ReSentEmployeeInviteRequest): NetworkRequest
    {
        return NetworkRequestHelper.POST(baseUrls.employee + "/resend-invite", req);
    }
}

export {
    EmployeeService
}