import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import reducers from '../../store/reducers';
import middlewares from '../../store/midlewares';

export const store = configureStore({
    reducer: reducers,
    middleware: new MiddlewareArray().concat(middlewares),
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>
