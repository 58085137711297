import React, { Component } from "react";
import { Link } from "react-router-dom";

function BannerSection() {
  return (
    <main>
      <section className="banner__area pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="banner__item p-relative mb-40">
                <div className="banner__content">
                  <span>Free</span>
                  <h3 className="banner__title">
                    <Link to="/course-details">
                      <a>
                        Germany Foundation <br /> Document
                      </a>
                    </Link>
                  </h3>
                  <Link to="/course-details">
                    <a className="e-btn e-btn-2">View Courses</a>
                  </Link>
                </div>
                <div className="banner__thumb d-none d-sm-block d-md-none d-lg-block">
                  <img
                    src="assets/img/banner/banner-img-1.png"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="banner__item p-relative mb-40">
                <div className="banner__content">
                  <span className="orange">new</span>
                  <h3 className="banner__title">
                    <Link to="/course-details">
                      Online Courses <br />
                      From Eduka University
                    </Link>
                  </h3>
                  <Link to="/course-details" className="e-btn e-btn-2">
                    Find Out More
                  </Link>
                </div>
                <div className="banner__thumb banner__thumb-2 d-none d-sm-block d-md-none d-lg-block">
                  <img
                    src="assets/img/banner/banner-img-2.png"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BannerSection;
