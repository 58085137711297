import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../redux/reducers';

const ProtectedRoute = () => {
    const { isLoggedIn } = useSelector((store: RootState) => store.auth);
  return (
    isLoggedIn ? <Outlet /> : <Navigate to="/sign-in" replace/>
  )
}

export default ProtectedRoute