import React, { useEffect, useState } from "react";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../config/store";
import { selectError, selectLoader } from "../../store/ui/selector";
import { useNavigate } from "react-router";
import { actions, getUserBasicProfile } from "../../store/profile/actions";
import OrgMain from "../Org-Details/OrgMain";
import {
  getAllCourses,
  actions as courseAction,
  getSelectedCourses,
} from "../../store/course/actions";
import CourseMain from "../Elements/Tab/CourseTab";

const DashboardMain = (props: any) => {
  const cntd = <LoadingOutlined style={{ fontSize: 89, color: "blue" }} spin />;

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { getBasicProf } = useSelector((store: RootState) => store.profile);
  const { allCourses } = useSelector((store: RootState) => store.course);
  // console.log(allCourses)

  useEffect(() => {
    if (getBasicProf.User.ActiveRole === "ORG_ADMIN") {
      dispatch(getAllCourses());
    }
    dispatch(getSelectedCourses());
  }, [getBasicProf]);

  return (
    <>
      {props.isLoading ? (
        <section className="signup__area po-rel-z1 pt-100 pb-145">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                <div className="sign__wrapper white-bg">
                  <div className="sign__form">
                    {props.isLoading && !props.error && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {cntd}
                        <br></br>
                        <br></br>
                        <span style={{ fontSize: 30, textAlign: "center" }}>
                          Please wait...
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (getBasicProf as any).User.ActiveRole === "ORG_ADMIN" &&
        (getBasicProf as any).User.UserJourney == 1 ? (
        <OrgMain />
      ) : (
        <CourseMain isLoading={props.isCourseLoading} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: selectLoader(actions.GET_USER_BASIC_PROFILE)(state),
    isCourseLoading: selectLoader(courseAction.GET_ALL_COURSES)(state),
    error: selectError(actions.GET_USER_BASIC_PROFILE)(state),
  };
};

export default connect(mapStateToProps)(DashboardMain);
