import React from "react";
import Service from "../../components/_home/ServiceSection";
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";

const WhyusPage = () => {
  return (
    <React.Fragment>
      <Header />
      <Service />
      <Footer />
    </React.Fragment>
  );
};

export default WhyusPage;
