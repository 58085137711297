import { createSelector } from "@reduxjs/toolkit";

export const selectLoader = (actionType: string) =>  createSelector(
    [
      state => state.ui
    ],
    (items) => {
        return !!items.spinners[actionType];
    }
  )


  export const selectError = (actionType: string) =>  createSelector(
    [
      state => state.ui
    ],
    (items) => {
        return items.errors[actionType];
    }
  )
  export const selectDoneAction = (actionType: string) =>  createSelector(
    [
      state => state.ui
    ],
    (items) => {
        return items.doneActions[actionType];
    }
  )