import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../config/store";
import { sessionLogout } from "../../../store/auth/actions";
import Restricted from "../../Restricted/Restricted";
import { ResourceActions } from "../../../config/res_action/resourceActions";

const BurgerMenus = ({setMenuOpen, menuOpen}:any) => {

  const router = useLocation();
  const [path, setPath] = useState("");
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    setPath(router.pathname);
  }, [router]);

  function logout() {
    dispatch(sessionLogout());
  }
  return (
    <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
      <div className="sidebar__wrapper">
        <div className="sidebar__close">
          <button
            className="sidebar__close-btn"
            id="sidebar__close-btn"
            onClick={() => setMenuOpen(false)}
          >
            <span>
              <i>
                <FontAwesomeIcon icon={["fas", "times"]} />
              </i>
            </span>
            <span>close</span>
          </button>
        </div>
        <div className="sidebar__content">
          <div className="logo mb-40">
            <Link to="/">
              <img height={36} src="/assets/img/logo/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="mm-menu">
            <ul>
              {isLoggedIn && (
                <>
                  {" "}
                  <li>
                    <Link to="/dashboard/home">Dashboard</Link>
                  </li>
                  <Restricted to={ResourceActions.EmployeeManagement.ViewAll}>
                    <li>
                      <Link to="/dashboard/employee-management">Employees</Link>
                    </li>
                  </Restricted>
                  <li onClick={logout}>
                    <Link to="/sign-in">Sign Out</Link>
                  </li>
                </>
              )}
              {!isLoggedIn && (
                <>
                  <li>
                    <Link to="/why-us">Why us</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/sign-in">Sign In</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenus;
