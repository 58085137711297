import { notification } from "antd";
import { AuthService } from "../../services/auth/AuthService";
import { EmployeeService } from "../../services/employee/employeeService";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";

export const employeeInviteFlow = getMiddleware({
    actionType: types.SENT_EMPLOYEE_INVITATION,
    onRequest: apiRequestHandler(EmployeeService.sendEmpInvitepLink),
    onSuccess: async ({dispatch}) => {
      notification.success({message: "An verification link is sent to your employee"})
    },
});
export const reSentEmployeeInviteFlow = getMiddleware({
    actionType: types.RESENT_EMPLOYEE_INVITATION,
    onRequest: apiRequestHandler(EmployeeService.reSendEmpInvitepLink),
    onSuccess: async ({dispatch}) => {
      notification.success({message: "An verification link is sent to your employee"})
    },
});

const middlewares = [
  employeeInviteFlow,
  reSentEmployeeInviteFlow
];

export default middlewares;