import { notification } from "antd";
import { CourseService } from "../../services/course/courseService";
import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { showError } from "../ui/actions";
import { actions as types } from "./actions";

export const getAllCoursesFlow = getMiddleware({
    actionType: types.GET_ALL_COURSES,
    onRequest: apiRequestHandler(CourseService.getAllCourses),
});

export const getSelectedCoursesFlow = getMiddleware({
    actionType: types.GET_SELECTED_COURSES,
    onRequest: apiRequestHandler(CourseService.getSelectedCourses),
});

export const selectCourseFlow = getMiddleware({
    actionType: types.SELECT_COURSE,
    onRequest: apiRequestHandler(CourseService.selectCourse),
    onError: async ({ type, error, dispatch }) => {
            // dispatch(showError("medium", error.message, "", type));
            notification.error({ message: error.message });
    },
    onSuccess: async ({ dispatch }) => {
        notification.success({ message: "Course added to your course list successfully" })
    },
});
export const removeCourseFlow = getMiddleware({
    actionType: types.DESELECT_COURSE,
    onRequest: apiRequestHandler(CourseService.deSelectCourse),
    onError: async ({ type, error, dispatch }) => {
            notification.error({ message: error.message });
    },
    onSuccess: async ({ dispatch }) => {
        notification.success({ message: "Course removed from your course list successfully" });
        // window.location.reload();
    },
});

const middlewares = [
    getAllCoursesFlow,
    getSelectedCoursesFlow,
    selectCourseFlow,
    removeCourseFlow
];

export default middlewares;