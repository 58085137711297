const TOKEN_ID = 'auth_token';

class Authenticator {
    static isLoggedIn = () => {
        return !!localStorage.getItem(TOKEN_ID);
    };

    static getToken = () => {
        return localStorage.getItem(TOKEN_ID);
    };

    static login = (jwt: string) => {
        return localStorage.setItem(TOKEN_ID, jwt);
    };

    static logOut = () => {
        return localStorage.removeItem(TOKEN_ID);
    };
}

export default Authenticator;