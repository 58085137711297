import React from 'react'
import ErrorMain from '../../components/404Notfound/ErrorMain';
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ErrorMain />
      <Footer />
    </React.Fragment>
  )
}

export default ErrorPage