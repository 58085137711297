import React from 'react'
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import SignUpMain from '../../components/SignUp/SignUpMain';

const SignUpPage = () => {
    return (
        <React.Fragment>
            <Header />
            <SignUpMain />
            <Footer />
        </React.Fragment>
    )
}

export default SignUpPage