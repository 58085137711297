import { createReducer } from "@reduxjs/toolkit";
import { actionSuccess } from "../helper";
import { actions as types } from "./actions";

export interface CourseModel {
  CourseId: string | null;
  CourseName: string | null;
  InstituteLogo: string | null;
  OfferedBy: string | null;
  Rating: number | null;
  Description: string | null;
  Thumbnail: string | null;
  Hero: string | null;
  Duration: string | null;
  Category: Array<string> | null;
  RedirectUrl: string | null;
  VideoUrl: string | null;
  Instructor: string | null;
  LastUpdateAt: string | null;
}

export interface State {
  allCourses: CourseModel[];
  slectedCourses: CourseModel[];
}

const initialState: State = {
  allCourses: [],
  slectedCourses: [],
};

export default createReducer(initialState, {
  [actionSuccess(types.GET_ALL_COURSES)]: (state, action) => {
    state.allCourses = action.payload;
  },
  [actionSuccess(types.GET_SELECTED_COURSES)]: (state, action) => {
    state.slectedCourses = action.payload;
  },
  [actionSuccess(types.SELECT_COURSE)]: (state, action) => {
    console.log(action.payload);
  },
  [actionSuccess(types.DESELECT_COURSE)]: (state, action) => {
    // console.log(action.payload);
  },
});
